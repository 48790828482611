const working = process.env.REACT_APP_CLIENT_KEY || "zervx-development";
console.log(process.env);
let host;
switch (working) {
  case "flixmobi":
    host = "https://api.flixmobi.net";
    break;
  default:
    host = "http://localhost:8080";
    break;
}

const urls = {
  MODE: working,
  HOST: host,
  REACT_CONFIG: "/api/admin/reactconfig",
  ADMIN_LANGUAGE_KEYS: "/api/admin/getLanguageKeys",
  TRACK: "/api/booking/track",
  TRACK_CURRENT_LOCATION: "/api/booking/track/location",
};
module.exports = urls;
